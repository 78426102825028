import React, { useEffect, useState } from "react";
import { databaseKey } from "../components/common/database_key";
import "../assets/css/notification.css";
import { notificationRef } from "../components/notification_ref";
import profile from "../assets/img/signup/person.webp";
import {
  fetchData,
  subscribeToData,
  updateData,
} from "../components/firebase/firebase_methods";
import { trackButtonClick } from "./event_track";
import NotificationMeta from "../metas/notification";

const Notification = () => {
  const [userName] = useState(localStorage.getItem("userName") || "");
  const [notifications, setNotifications] = useState([]);
  const collectionName = databaseKey();

  useEffect(() => {
    const notification_ref = notificationRef(collectionName, userName);
    const unsubscribe = subscribeToData(notification_ref, (snapshot) => {
      const data = snapshot;
      if (data) {
        const notificationKeys = Object.keys(data);
        setNotifications(
          notificationKeys.map((key) => ({ key, ...data[key] }))
        );
      } else {
        setNotifications([]);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleRead = (key) => {
    updateData(fetchData(`${collectionName}/notification/${userName}/${key}`), {
      read: true,
    });
  };

  const handleMarkAsRead = () => {
    trackButtonClick("Notification Page");
    notifications.forEach(({ key }) => {
      handleRead(key);
    });
  };

  const getTimeAgo = (timestamp) => {
    const current = new Date();
    const previous = new Date(timestamp);
    const seconds = Math.floor((current - previous) / 1000);
    let interval = Math.floor(seconds / 86400);
    if (interval >= 1) {
      return interval + " day ago";
    }
    interval = Math.floor(seconds / 3600);
    if (interval >= 1) {
      return interval + " hour ago";
    }
    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
      return interval + " minute ago";
    }
    return Math.floor(seconds) + " second ago";
  };

  return (
    <>
      <NotificationMeta />
      <main id="main">
        <>
          <div className="breadcrumbs" data-aos="fade-in">
            <div className="container">
              <h2>Notification</h2>
              <p>
                Receive instant updates on the latest math challenges, competitions,
            and events.
              </p>
            </div>
          </div>
          <div>
              {notifications.length !== 0 ? (
                <div className="container" data-aos="fade-up">
            
                  <button
                    className="btn btn-outline-success mark-as-read"
                    onClick={handleMarkAsRead}
                  >
                    Mark All as Read
                  </button>
                  <div style={{clear: "both"}}></div>
                </div>
              ) : (
                <div className="container" data-aos="fade-up">
                  <p></p>
                </div>
              )}
            <div className="container" data-aos="fade-up">
              <div className="message-container">
                {notifications.length === 0 ? (
                  <p className="not-found">No notifications found</p>
                ) : (
                  notifications.map(({ key, message, read, timestamp }) => (
                    <div
                      key={key}
                      className={`message ${
                        read ? "read-messages" : "unread-messages"
                      }`}
                      onClick={() => handleRead(key)}
                    >
                      <div className="message-content">
                        <p>
                          <img src={profile} alt="Profile" className="message-img" />
                          {message}
                        </p>
                        <span className="time">{getTimeAgo(timestamp)}</span>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </>
      </main>
    </>
  );
};

export default Notification;
